<script setup lang="ts">
import {computed} from "vue";
import {services} from "@/config/services.ts";
import {useDataStore} from "@/store/data";
import {formatPhone} from "@/helpers/phone";
import {marked} from "marked";

const {state} = useDataStore()
const {t, locale} = useI18n();
const localePath = useLocalePath();
const contacts = computed(() => {
  return state.contacts?.records?.map((contact) => {
    return {
      title: locale.value === 'et' ? contact.fields.name_et : contact.fields.name_ru,
      value: contact.fields.value,
      type: contact.fields.type
    }
  })
});
const textRegData = computed(() => {
  const block = state.blocks?.records?.find((block: any) => {
    return block.fields.id === `block-reg-data-${locale.value}`
  })
  return block?.fields?.Text ? marked.parse(block.fields.Text) : '';
});
</script>
<template>
  <footer class="border-t pt-10 pb-8">
    <div class="container flex flex-col md:flex-row items-start gap-6 md:gap-24">
      <nuxt-link :to="localePath('index')" :title="t('section.hero.title')">
        <AppLogo class="h-16 -translate-y-2"/>
      </nuxt-link>
      <div>
        <nuxt-link :to="localePath(`services`)" class="font-bold mb-4 text-lg block" :title="t('label.services')">
          {{ t("label.services") }}
        </nuxt-link>
        <ul class="flex flex-col gap-4 -ml-2 md:-ml-3">
          <li v-for="(service, index) in services" :key="index">
            <nuxt-link :to="localePath(`/services/${service.slug}`)"
                       class="link text-base font-medium leading-6 text-gray-900 hover:bg-gray-50 px-2 md:px-3 py-1 rounded-[8px]" :title="t(service.title)">
              {{ t(service.title) }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="flex-1 h-full">
        <span class="font-bold mb-4 text-lg block">{{ t("label.contacts") }}</span>
        <div class="flex flex-wrap pb-6 gap-y-3 gap-x-10 pb-6 -ml-3">
          <template v-if="state.loading">
            <div v-for="index in 3" :key="index" class="w-fit px-2 md:px-3 py-2 flex flex-col gap-2">
              <span class="animate-pulse h-4 w-16 bg-gray-100 rounded-[6px]" />
              <span class="animate-pulse h-4 w-24 bg-gray-100 rounded-[6px]" />
            </div>
          </template>
          <template v-else>
            <a v-for="(contact, index) in contacts" :key="index" :href="contact.type === 'email' ? `mailto:${contact.value}` : `tel:${formatPhone(contact.value)}`" class="w-fit px-3 py-2 rounded-[8px] hover:bg-gray-50 flex flex-col leading-none gap-2">
              <span class="font-bold">{{contact.title}}</span>
              <span>{{contact.value}}</span>
            </a>
          </template>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="w-full text-sm opacity-80 py-2">
        <template v-if="state.loading">
          <div class="animate-pulse h-5 w-full md:w-8/12 md:mx-auto xl:w-5/12 xl:mr-0 bg-gray-100 rounded-[6px]" />
        </template>
        <div v-else class="text-left md:text-center xl:text-right" v-html="textRegData" />
      </div>
    </div>
  </footer>
</template>