<script setup>
import {ref} from 'vue'
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import {useLocalePath} from "#i18n";
import {services} from "@/config/services.ts";
import {useRoute} from "vue-router";

const route = useRoute();
const mobileMenuOpen = ref(false);
const {t, locale} = useI18n();
const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();

const navServices = services.value.map((service) => {
  return {
    name: service.title,
    description: service.navDescription,
    to: `/services/${service.slug}`,
    icon: service.navIcon
  }
})
</script>

<template>
  <header class="bg-white/80 backdrop-blur-xl border-b z-10">
    <div class="container">
      <nav class="flex items-center justify-between py-3" aria-label="Header menu">
        <div class="flex lg:flex-1">
          <nuxt-link :to="localePath('index')" class="-m-1.5 p-1.5" :title="t('section.hero.title')">
            <span class="sr-only">CURSUS OÜ</span>
            <AppLogo class="h-16"/>
          </nuxt-link>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  @click="mobileMenuOpen = true">
            <span class="sr-only">Menu</span>
            <span class="i-heroicons-bars-3 h-6 w-6" aria-hidden="true"/>
          </button>
        </div>
        <PopoverGroup class="hidden lg:flex lg:gap-x-6 z-20">
          <nuxt-link :to="localePath('index')"
                     class="link text-base font-semibold leading-6 text-gray-900 hover:bg-gray-50 lg:px-3 py-1 rounded-[8px]">
            {{ t("label.home") }}
          </nuxt-link>
          <Popover class="relative" v-slot="{ close, open }">
            <PopoverButton
                :class="{'bg-gray-100 hover:bg-gray-100': route.path.includes('services') || open}"
                class="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-50 px-3 py-1 rounded-[8px]">
              {{ t('label.prices') }}
              <span class="i-heroicons-chevron-down ui-open:rotate-180 h-4 w-4 flex-none text-gray-400"
                    aria-hidden="true"/>
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
                        enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
                        leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel
                  class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div class="p-4">
                  <div v-for="item in navServices" :key="item.name"
                       class="group relative flex items-center gap-x-6 rounded-lg p-4 text-base leading-6 hover:bg-gray-50">
                    <div class="flex-auto">
                      <NuxtLink :to="localePath(item.to)" class="block font-semibold text-gray-900" @click="close()" :title="t('section.hero.title')">
                        {{ t(item.name)  }}
                        <span class="absolute inset-0"/>
                      </NuxtLink>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>

          <nuxt-link :to="localePath('contacts')"
                     :title="t('label.contacts')"
                     class="link text-base font-semibold leading-6 text-gray-900 hover:bg-gray-50 px-3 py-1 rounded-[8px]">
            {{ t("label.contacts") }}
          </nuxt-link>
        </PopoverGroup>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <NuxtLink v-if="locale === 'ru'" :to="switchLocalePath('et')"
                    class="hover:bg-gray-50 px-3 py-1 rounded-[8px]">Eesti
          </NuxtLink>
          <NuxtLink v-else :to="switchLocalePath('ru')" class="hover:bg-gray-50 px-3 py-1 rounded-[8px]">Русский
          </NuxtLink>
        </div>
      </nav>
      <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-10"/>
        <DialogPanel
            class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white py-3 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="container">
            <div class="flex items-center justify-between">
              <nuxt-link :to="localePath('index')" class="-m-1.5 p-1.5" @click="mobileMenuOpen = false" :title="t('section.hero.title')">
                <span class="sr-only">CURSUS OÜ</span>
                <AppLogo class="h-16"/>
              </nuxt-link>
              <button type="button"
                      class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                      @click="mobileMenuOpen = false">
                <span class="sr-only">Close menu</span>
                <span class="i-heroicons-x-mark h-6 w-6" aria-hidden="true"/>
              </button>
            </div>
            <div class="mt-6 flow-root">
              <div class="-my-6 divide-y divide-gray-500/10">
                <div class="space-y-2 py-6">
                  <Disclosure as="div" class="-mx-2" v-slot="{ open }">
                    <DisclosureButton
                        class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      {{ t('label.prices') }}
                      <span class="i-heroicons-chevron-down transition-all"
                            :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']"
                            aria-hidden="true"/>
                    </DisclosureButton>
                    <DisclosurePanel class="mt-2 space-y-2">
                      <DisclosureButton v-for="item in [...navServices]" :key="item.name" as="div"
                                        @click="mobileMenuOpen = false; $router.push(localePath(item.to))"
                                        class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        {{ t(item.name) }}
                      </DisclosureButton>
                    </DisclosurePanel>
                  </Disclosure>
                  <nuxt-link :to="localePath('contacts')"
                             @click="mobileMenuOpen = false"
                             :title="t('label.contacts')"
                             class="link -mx-2 block rounded-lg px-2 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    {{ t("label.contacts") }}
                  </nuxt-link>
                </div>
                <div class="py-6">
                  <NuxtLink v-if="locale === 'ru'" :to="switchLocalePath('et')" @click="mobileMenuOpen = false">Eesti
                  </NuxtLink>
                  <NuxtLink v-else :to="switchLocalePath('ru')" @click="mobileMenuOpen = false">Русский</NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </div>
  </header>
</template>