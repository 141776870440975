<script setup lang="ts">
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import {useDataStore} from "@/store/data";

const {fetchData} = useDataStore();
const i18nHead = useLocaleHead({
  addDirAttribute: undefined,
  identifierAttribute: undefined,
  addSeoAttributes: {}
});
useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || [])
  ]
});
fetchData();
</script>

<template>
  <AppHeader class="sticky top-0 z-90"/>
  <main class="flex-1 z-80">
    <slot/>
  </main>
  <AppFooter/>
</template>